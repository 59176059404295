.banner-heading {
	padding: 30px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.heading-title {
	margin: 0;
	font-size: 34px;
}
