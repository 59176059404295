.navbar__container {
  padding: 0 10px;
  /* text-align: center; */
  background-color: #c93375;
  header {
    position: relative;
  }

  .header-top {
    display: flex;
    padding: 3px 20px;
    align-items: center;
    justify-content: space-between;
  }

  .logo img {
    width: 100px;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  .header-select .MuiOutlinedInput-root {
    background: #fafafa;
    color: black;
    padding: 2px 10px !important;
    border-radius: 25px;
    font-size: 14px;
  }

  .header-select .MuiSelect-select {
    padding: 0 !important;
  }

  .header-select .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  .header-select svg {
    display: none;
  }

  .MuiPaper-elevation {
    margin-top: 10px !important;
    filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px) !important;
    border-radius: 4px;
    padding: 3px 0;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ul li {
    list-style-type: none;
    display: inline-block;
    margin-left: 20px;
  }

  .MuiMenuItem-root {
    margin-left: 0;
    width: 100%;
    padding: 4px 16px;
    font-size: 14px;
    font-weight: 500;
  }

  ul li:first-child {
    margin-left: 0;
  }

  ul li a {
    font-size: 14px;
    padding: 20px 0;
    color: #ffffff;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
    transition: all 0.4s ease;
    border-bottom: 2px solid transparent;
  }

  ul li a.active {
    border-bottom: 2px solid #ffffff;
  }

  ul li a:hover {
    border-bottom: 2px solid #ffffff;
  }
}
.faAngleDown:hover {
  color: inherit !important;
}
.navbar__container ul li a {
  padding: 0 !important; /* Remove the padding for anchor elements within this specific context */
}
// .no-link-styles {
//   text-decoration: none;
//   color: inherit;
//   cursor: pointer;
// }
