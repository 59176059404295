.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order__heading {
  font-size: 18px;
  font-weight: 700;
}

.details-fee {
  margin-top: 25px;
}

.order_ul {
  padding: 0;
}

.details-fee li {
  display: flex;
  margin-bottom: 15px;
  font-size: 15px;
  justify-content: space-between;
}

.details-fee li .left {
  margin-right: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.904);
  font-size: 14px;
}

.details-fee li .right {
  font-size: 13px;
  color: #575962;
}

.filter {
  margin-top: 25px;
}