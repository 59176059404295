@import url("https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight&family=Montserrat&family=Open+Sans&family=Poppins&family=Titillium+Web&display=swap");

body {
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Inter Tight', sans-serif; */
  font-family: "Inter", sans-serif;
  /* font-family: 'Open Sans', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  background-color: #eeeeee !important;
}

a {
  color: #1e2731;
  transition: all 0.3s ease;
}

a:hover {
  color: #c93375;
}

.ql-editor {
  height: 10rem !important;
}

.goback a {
  color: #1e2731;
  display: flex;
  align-items: center;
  padding-top: 25px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
}

.goback a:hover {
  color: #c93375;
}

@media print {
  header,
  #orderLogsBox,
  #adminMessageBox,
  #orderDetailHide,
  #orderOperationsCard {
    display: none !important;
  }
  .details .left,
  .details .right {
    width: 100%;
  }
}
